<template>
  <div>
    <modal-wrapper
      :is-modal-wrapper-show="guideShow"
      :is-show-transition="true"
      @close="onClickGuide"
    >
      <div
        class="feature-guide w-100 h-100 position-relative"
        :style="guideStyle"
        @click.stop="onClickGuide"
      >
        <template v-for="(feature, index) in Object.values(features)">
          <div v-if="feature.key === currentGuide" :key="feature.key">
            <div
              class="feature-wrap position-absolute d-flex align-items-center justify-content-center"
              :style="`right: ${index * 50 + index * 2 + 2}px`"
            >
              <i
                class="text-32 text-primary d-block"
                :class="`icon-${feature.icon}`"
              ></i>
            </div>
            <p
              class="guide-text m-0 text-white position-absolute"
              :style="`right: ${(index + 1) * 37}px`"
            >
              {{ feature.title }}
            </p>
            <div
              class="arrow position-absolute"
              :style="`right: ${(index + 1) * 44}px`"
            >
              <svg width="60" height="48">
                <path
                  d="M46 3 L60 1, 60 0"
                  stroke-width="2"
                  stroke="white"
                  fill="none"
                />
                <path
                  d="M59 1 L59 14, 60 0"
                  stroke-width="2"
                  stroke="white"
                  fill="none"
                />
                <path
                  d="M0 50 Q30 40, 60 0"
                  stroke-width="2"
                  stroke="white"
                  fill="none"
                />
              </svg>
            </div>
          </div>
        </template>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/Base/ModalWrapper.vue';

export default {
  components: {
    ModalWrapper,
  },
  props: {
    features: {
      type: Object,
      required: true,
    },
    currentGuide: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      guideShow: false,
      guideStyle: {
        opacity: 0,
      },
    };
  },
  created() {
    this.guideShow = true;
    setTimeout(() => {
      this.guideStyle.opacity = 1;
    }, 0);
  },
  methods: {
    onClickGuide() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-guide {
  opacity: 0;
  transition: all 0.3s;
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
  .feature-wrap {
    width: 52px;
    height: 52px;
    background: #fff4e0;
    border-radius: 50%;
    right: 2px;
    top: calc(env(safe-area-inset-top) + 2px);
  }
  .guide-text {
    right: 37px;
    top: calc(104px + env(safe-area-inset-top));
  }
  .arrow {
    right: 44px;
    top: calc(56px + env(safe-area-inset-top));
  }
}
</style>
