<template>
  <div
    class="course position-relative bg-white d-flex align-items-center justify-content-between overflow-hidden"
    :class="[
      $device.isMobile ? 'app-course px-4' : 'web-course pr-2',
      {'shadow-md': $device.isMobile && $isJungo},
      {'shadow-sm': $device.isMobile && !$isJungo},
      {
        'is-last-watch': course.isLastWatch,
        'is-chosen': anchorDay === dailyNumber && courseNumber === 0,
      },
    ]"
    @click="onClickCourse"
  >
    <template v-if="$device.isMobile">
      <div v-if="isLock" class="mask position-absolute">
        <i
          class="lock icon-Lock d-flex align-items-center text-32 text-white position-absolute"
        ></i>
      </div>
      <!-- <div
        v-if="course.isLastWatch"
        class="keep-learning bg-accent position-absolute text-white text-12"
      >
        {{ $t('上次觀看') }}
      </div> -->
      <div class="course-details d-flex align-items-center">
        <div
          v-if="!$device.isMobile"
          class="order text-white d-flex align-items-center justify-content-center mr-4 bg-primary"
        >
          {{ courseNumber + 1 }}
        </div>
        <div class="content w-100 text-truncate">
          <h3
            class="content-title text-16 font-weight-normal w-100 text-truncate"
          >
            {{ courseTitle }}
          </h3>
          <p class="content-subtitle text-14 m-0 text-truncate">
            {{ vedioTimeText }}
          </p>
        </div>
      </div>
      <div v-if="isPassed" class="check d-flex">
        <i class="icon-Circle-check"></i>
      </div>
    </template>
    <template v-else>
      <b-button
        :variant="
          (!$isJungo && course.type === 'VIDEO') ||
          ($isJungo && course.type !== 'VIDEO')
            ? 'primary'
            : 'secondary'
        "
        size="md"
        class="course-btn"
      >
        <p class="m-0 text-truncate d-flex align-items-center">
          <i
            v-if="course.type === 'VIDEO'"
            class="icon-Circle-play text-20 mr-1"
          ></i>
          {{ webCourseTitle }}
          <span v-if="isPassed" class="icon-Circle-check ml-1 text-20"></span>
        </p>
      </b-button>
    </template>
  </div>
</template>

<script>
import tsumeGameService from '@/services/tsumeGame.js';
import aiLevelMapping from '@/lib/base/aiLevel.js';

export default {
  props: {
    course: {
      type: Object,
      required: true,
    },
    courseNumber: {
      type: Number,
      default: 0,
      required: true,
    },
    dailyNumber: {
      type: Number,
      default: 1,
    },
    isLock: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    courseTitle() {
      if (this.course.type === 'VIDEO') {
        return this.course.title;
      } else if (this.course.type === 'GAME') {
        return this.$t('下棋');
      } else if (this.course.type === 'TSUME') {
        return this.$t('做題');
      } else {
        return this.$t('棋力檢定');
      }
    },
    vedioTimeText() {
      if (this.course.type === 'VIDEO' && this.course.videoDuration) {
        if (this.course.videoDuration < 60) {
          let secondText = this.course.videoDuration;
          if (this.course.videoDuration < 10) {
            secondText = `0${this.course.videoDuration}`;
          }
          return `00:${secondText}`;
        } else {
          const minute = Math.floor(this.course.videoDuration / 60);
          const second = this.course.videoDuration % 60;
          let minuteText = minute;
          let secondText = second;

          if (minute < 10) {
            minuteText = `0${minute}`;
          }
          if (second < 10) {
            secondText = `0${second}`;
          }
          return `${minuteText}:${secondText}`;
        }
      } else {
        return '';
      }
    },
    webCourseTitle() {
      if (this.course.type === 'VIDEO') {
        return this.dailyNumber === 0
          ? `${this.course.title} ${this.vedioTimeText}`
          : `${this.$t('課程影片')} ${this.vedioTimeText}`;
      } else if (this.course.type === 'GAME') {
        // 防止prettier自動換行產生空白
        // prettier-ignore
        return this.$device.isMobile
          ? `${this.$t('下棋')}-${aiLevelMapping.processAiLevel(this.course.aiLevel)}`
          : `${this.$t('下棋')}`;
      } else {
        return this.$t('做題');
      }
    },
    isPassed() {
      return this.course.status === 'COMPLETED';
    },
    anchorDay() {
      return this.$store.getters['course/anchorDay'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  watch: {
    'course.isLastWatch': {
      handler() {
        if (this.course.isLastWatch && !this.anchorDay) {
          // 如果從個人頁面點選號碼跳轉後，anchorDay便會有值，並且取消上次觀看的定位點
          this.locateToLastPlayed();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.anchorDay !== null) {
      this.locateToAnchorDay();
    }
  },
  methods: {
    locateToLastPlayed() {
      if (this.course.isLastWatch) {
        setTimeout(() => {
          document.querySelector('.is-last-watch').parentNode.scrollIntoView({
            block: 'center',
          });
        }, 0);
      }
    },
    locateToAnchorDay() {
      if (this.anchorDay) {
        setTimeout(() => {
          document.querySelector('.is-chosen').parentNode.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 0);
      }
    },
    onClickCourse() {
      if (!this.isLoading && !this.isLock) {
        if (this.course.type === 'VIDEO') {
          this.onClickVideo();
        } else if (this.course.type === 'GAME') {
          this.onClickGame();
        } else {
          this.onClickTsume();
        }
      }
    },
    onClickVideo() {
      if (this.isLock) {
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      this.$store.commit('aiCourse/setUrlData', {
        aiCourseId: this.course.aiCourseId,
        dailyNumber: this.dailyNumber,
        courseUniqueKey: encodeURIComponent(this.course.courseUniqueKey),
        videoId: this.course.videoId,
      });
    },
    async onClickGame() {
      if (this.isLock) {
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      const actionApi = !this.course.gameCreatedTime
        ? 'createCourseGame'
        : 'getUnfinishedCourseGame';

      const courseUniqueKey = this.course.courseUniqueKey;
      const coursePlan = this.currentPlan.id;
      this.$store
        .dispatch(`aiGame/${actionApi}`, {courseUniqueKey, coursePlan})
        .then(() => {
          return this.$router.push(
            `/course/game/${encodeURIComponent(this.course.courseUniqueKey)}`
          );
        })
        .catch(() => {
          this.$Message.error(this.$t('發生未知錯誤，再試一次'), 3000);
        })
        .finally(() => {
          this.$store.commit('env/setIsLoading', false);
        });
    },
    async onClickTsume() {
      if (this.isLock) {
        return;
      }
      if (!this.course.tsumeCreatedTime) {
        this.$store.commit('env/setIsLoading', true);

        await tsumeGameService
          .createCourseTsume(this.course.courseUniqueKey)
          .then(() => {
            return this.$router.push({
              path: `/course/tsume/${encodeURIComponent(
                this.course.courseUniqueKey
              )}/types/${this.course.title}`,
            });
          })
          .catch(() => {
            this.$Message.error(this.$t('發生未知錯誤，再試一次'), 3000);
          })
          .finally(() => {
            this.$store.commit('env/setIsLoading', false);
          });
      } else {
        return this.$router.push({
          path: `/course/tsume/${encodeURIComponent(
            this.course.courseUniqueKey
          )}/types/${this.course.title}`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.course {
  &.app-course {
    height: 80px;
    border-radius: $rounded-md;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .keep-learning {
    top: 0;
    right: 0;
    border-radius: 0 10px 0 10px;
    padding: 2px 8px;
  }
  .course-details {
    width: 75%;
    .order {
      min-width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .content-subtitle {
      color: $font-grayscale-3;
    }
  }
  .check {
    color: $primary;
    font-size: 36px;
  }
  .mask {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $grayscale-30;
    z-index: 1;
    border-radius: 10px;
  }
  .lock {
    top: 0;
    bottom: 0;
    right: 30px;
  }
}
</style>
