<template>
  <div class="home d-flex flex-column overflow-hidden position-relative">
    <loading-wrapper v-if="isLoading" class="loading"></loading-wrapper>

    <home-header
      v-if="$device.isMobile"
      @switch-course="$store.commit('env/setIsSwitchCourseSheetShow', true)"
      @on-click-sidebar="onClickSidebar"
    ></home-header>
    <base-header
      v-else
      mode="primary"
      :title="$t('課程')"
      :is-title-bold="true"
    ></base-header>

    <div class="home-body pb-2 pb-md-6 scroll-bar position-relative h-100">
      <div class="banner-container position-relative mt-2 mt-md-6 mb-md-4">
        <HomeBanner />
      </div>

      <PlansDropdown
        v-if="!noPlans && !$device.isMobile && !isLoading"
        class="plans-wrapper"
      />

      <div class="courses-container position-relative">
        <div class="course-wrapper rounded-md">
          <no-lesson-text v-if="noPlans"></no-lesson-text>
          <daily-courses v-else></daily-courses>
        </div>
      </div>
    </div>

    <modal-confirm
      v-if="errorMessage"
      :hide-cancel="true"
      @confirm="confirmErrorMessage"
    >
      <template>
        {{ errorMessage }}
      </template>
    </modal-confirm>
    <FeatureGuide
      v-if="mobileCurrentGuide"
      ref="FeatureGuide"
      :features="guideFeatures"
      :current-guide="mobileCurrentGuide"
      @close="closeGuide"
    ></FeatureGuide>
    <ModalGetCoupon
      v-if="isModalGetCouponShow"
      :coupon-message="couponNotification.modal.message"
      :coupon-title="couponNotification.modal.title"
      :coupon-ribbon="couponNotification.modal.ribbon"
      @item-click="onClickModalGetCoupon"
    ></ModalGetCoupon>
    <switch-plan-guide-web-version
      v-if="isWebGuideShow"
      @closeGuide="closeGuide"
    ></switch-plan-guide-web-version>
  </div>
</template>

<script>
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import HomeHeader from '@/components/Home/HomeHeader.vue';
import HomeBanner from '@/components/Home/HomeBanner.vue';
import BaseHeader from '@/components/Base/BaseHeader';
import DailyCourses from '@/components/Course/DailyCourses.vue';
import PlansDropdown from '@/components/Base/PlansDropdown.vue';
import NoLessonText from '@/components/Base/NoLessonText.vue';
import SwitchPlanGuideWebVersion from '@/components/Base/SwitchPlanGuideWebVersion.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import ModalGetCoupon from '@/components/Modal/ModalGetCoupon.vue';
import FeatureGuide from '@/components/Base/FeatureGuide.vue';
import plansMixin from '@/mixin/plans';
import BackEvent from '@/lib/base/backEvent.js';
import {getLocalData, saveLocalData} from '@/lib/base/localData';

export default {
  components: {
    LoadingWrapper,
    BaseHeader,
    HomeBanner,
    HomeHeader,
    DailyCourses,
    NoLessonText,
    ModalConfirm,
    ModalGetCoupon,
    FeatureGuide,
    PlansDropdown,
    SwitchPlanGuideWebVersion,
  },
  mixins: [plansMixin],
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      guideFeatures: {
        notification: {
          key: 'has-notification-guide',
          icon: 'Bell',
          title: this.$t('這邊可以查看推播通知喔'),
        },
        switchPlans: {
          key: 'has-switch-plans-guide',
          icon: 'Arrow-left-right',
          title: this.$t('這邊可以切換新的課程喔'),
        },
      },
      mobileCurrentGuide: '',
      isWebGuideShow: false,
      webWatcher: new IntersectionObserver(
        ([e]) => e.target.classList.toggle('is-top', e.intersectionRatio < 1),
        {threshold: [1]}
      ),
      mobileWatcher: new IntersectionObserver(
        ([e]) => {
          document
            .querySelector('.home-header')
            .classList.toggle('second-mode', e.intersectionRatio < 1);
        },
        {threshold: [1]}
      ),
      scrollTopNum: null,
      isModalGetCouponShow: false,
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    currentCourses() {
      return this.$store.getters['course/currentCourses'];
    },
    noPlans() {
      return this.$store.getters['course/noPlans'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    plans() {
      return this.$store.getters['course/plans'] || [];
    },
    hasMultiplePlans() {
      return this.plans.length > 1;
    },
    isCN() {
      return this.$store.getters['env/isCN'];
    },
    notifications() {
      return this.$store.getters['notification/notifications'];
    },
    couponNotification() {
      const _couponNotification = this.notifications.find((notfication) => {
        return notfication.modal && !notfication.hasModalShown;
      });
      return _couponNotification;
    },
  },
  watch: {
    couponNotification(newValue) {
      if (newValue) {
        this.isModalGetCouponShow = true;
      }
    },
  },
  async created() {
    if (this.noPlans) {
      return;
    }
    this.isLoading = true;
    await this.$store.dispatch('course/getCourses', this.currentPlan.id);
    if (this.isPlanKid2week) {
      await this.$store.dispatch('liveCourse/getKid2WeekLiveCourses');
    }
    this.isLoading = false;

    this.$store.dispatch('verification/getVerification', this.currentPlan.id);

    if (this.$route.query.error) {
      this.errorMessage = this.$route.query.error;
    }

    this.checkFeatureGuide();
    BackEvent.clearEvents();

    await this.$nextTick();
    if (this.$device.isMobile && !this.$isJungo) {
      const body = document.querySelector('.home-body');
      body.addEventListener('scroll', this.watchFirstScroll);
    } else {
      const el = document.querySelector('.plans-wrapper');
      if (el) {
        this.webWatcher.observe(el);
      }
    }
  },
  mounted() {
    if (this.couponNotification) {
      this.isModalGetCouponShow = true;
    }
  },
  beforeDestroy() {
    this.webWatcher.disconnect(document.getElementById('.plans-wrapper'));
    this.mobileWatcher.disconnect(document.getElementById('.banner-container'));
    this.removeScrollWatcher();
  },
  methods: {
    watchFirstScroll(e) {
      if (this.scrollTopNum == null) {
        this.scrollTopNum = e.target.scrollTop;
      } else if (this.scrollTopNum !== e.target.scrollTop) {
        this.hasScroll = true;
        const el = document.querySelector('.banner-container');
        if (el) {
          this.mobileWatcher.observe(el);
        }
        this.removeScrollWatcher();
      }
    },
    removeScrollWatcher() {
      const body = document.querySelector('.home-body');
      body.removeEventListener('scroll', this.watchFirstScroll);
    },
    checkFeatureGuide() {
      if (this.$device.isMobile) {
        if (
          !this.isCN &&
          !this.$isJungo &&
          !JSON.parse(getLocalData(this.guideFeatures.notification.key))
        ) {
          this.mobileCurrentGuide = this.guideFeatures.notification.key;
        } else if (
          this.hasMultiplePlans &&
          !JSON.parse(getLocalData(this.guideFeatures.switchPlans.key))
        ) {
          this.mobileCurrentGuide = this.guideFeatures.switchPlans.key;
        } else {
          this.mobileCurrentGuide = '';
        }
      } else {
        if (
          this.hasMultiplePlans &&
          !JSON.parse(getLocalData('has-switch-plans-guide')) &&
          !this.$isJungo
        ) {
          this.isWebGuideShow = true;
        }
      }
    },
    confirmErrorMessage() {
      this.errorMessage = null;
      this.$router.replace({query: null});
    },
    closeGuide() {
      if (this.$device.isMobile) {
        saveLocalData(this.mobileCurrentGuide, JSON.stringify(true));
        this.checkFeatureGuide();
      } else {
        this.isWebGuideShow = false;
        saveLocalData('has-switch-plans-guide', JSON.stringify(true));
      }
    },
    onClickSidebar() {
      this.$store.commit('env/setIsSidebarShow', true);
      BackEvent.addEvent(() => {
        this.$store.commit('env/setIsSidebarShow', false);
      });
    },
    closeSidebar() {
      this.$store.commit('env/setIsSidebarShow', false);
    },
    onClickModalGetCoupon(value) {
      this.isModalGetCouponShow = false;
      this.$store.dispatch(
        'notification/setNotificationModal',
        this.couponNotification.sk
      );
      if (value === 'check-coupon') {
        return this.$router.push({name: this.couponNotification.appRouterName});
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'home' && from.name === 'video' && !this.$device.isMobile) {
      this.$store.commit('aiCourse/setIsVisible', false);
      this.$store.commit('aiCourse/setUrlToHomePage');
      this.$store.dispatch('course/getCourses', this.currentPlan.id);
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: $bgcontainer;
  height: calc(
    100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 62px
  );
  .home-body {
    overflow: overlay;
  }
  .banner-container,
  .courses-container {
    padding: 0px 16px;
  }
  .course-wrapper {
    background: $bgsection;
    padding: 8px;
    margin-top: 8px;
  }
  .plans-wrapper {
    &.is-top {
      background: $bgsection;
      .plan-container {
        border-bottom: none;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .home {
    height: 100vh;
    .banner-container,
    .courses-container {
      max-width: 720px;
      margin: 0 auto;
    }
    .course-wrapper {
      margin-top: 0;
      padding: 0;
      background: none;
    }
    /deep/ .plan-container {
      max-width: 720px;
      padding: 0 16px;
      &::before {
        width: calc(100% - 32px);
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .home {
    .banner-container,
    .courses-container {
      max-width: 1140px;
    }
    /deep/ .plan-container {
      max-width: 1140px;
    }
  }
}
</style>
