<template>
  <div
    class="daily-courses"
    :class="$device.isMobile ? 'app-daily' : 'web-daily'"
  >
    <template v-if="$device.isMobile">
      <div
        v-for="daily in currentCourses"
        :key="daily.day"
        class="daily-course"
      >
        <div
          class="header text-white text-18 mb-2 px-3 d-flex justify-content-between"
        >
          <span class="font-weight-bold">{{
            getCourseTitle(daily.day, '')
          }}</span>
          <span v-if="daily.isLock">
            {{ getOpenDay(daily.openedAt) }} 開放</span
          >
        </div>
        <course-card
          v-for="(course, index) in daily.content"
          :key="course.courseUniqueKey"
          :is-lock="daily.isLock"
          :course="course"
          :course-number="index"
          :daily-number="daily.day"
        >
        </course-card>
      </div>
      <div
        v-if="dailyLiveList.length"
        class="header text-white text-18 mb-2 px-3 d-flex justify-content-between"
      >
        必修直播課
      </div>
      <live-card
        v-for="liveCourse of dailyLiveList"
        :key="liveCourse.liveCourseId"
        class="daily-live-card"
        :live-course="liveCourse"
        :show-tags="false"
        :show-subscription="false"
        @enterLiveRoom="enterLiveRoom"
        @watchRecord="watchRecord"
        @copy="copy"
      ></live-card>
      <ShoppingGuideCard
        v-if="isShoppingGuideShow"
        :title="$t('覺得圍棋簡單又好玩嗎？')"
        :sub-title="$t('想學得更多！點此前往官網購買')"
        @onClickShoppingGuide="onClickShoppingGuide"
      ></ShoppingGuideCard>
    </template>
    <template v-else>
      <div
        v-for="daily in currentCourses"
        :key="daily.day"
        class="daily-course position-relative d-flex align-items-center bg-white p-3"
      >
        <div v-if="daily.isLock" class="mask position-absolute">
          <div
            class="lock text-white position-absolute text-center d-flex flex-column justify-content-center"
          >
            <i class="icon-Lock text-32"></i>
            <p class="mt-2 mb-0">{{ getOpenDay(daily.openedAt) }} 開放</p>
          </div>
        </div>
        <!-- <div
          v-if="hasLastWatch(daily)"
          class="keep-learning bg-accent position-absolute text-white"
        >
          {{ $t('上次觀看') }}
        </div> -->
        <div
          class="cover-wrapper h-100 position-relative overflow-hidden mr-4"
          @click="onClickVideo(daily)"
        >
          <img
            v-if="!isDataLoading"
            loading="lazy"
            class="h-100"
            :src="getVideoCover(daily.content[0].videoId)"
          />
          <div
            class="cover-bg position-absolute w-100 h-100 align-items-center justify-content-center"
          >
            <div
              class="btn-wrapper bg-primary d-flex align-items-center justify-content-center"
            >
              <i class="icon-Play text-white"></i>
            </div>
          </div>
        </div>

        <div class="content-wrapper">
          <div class="course-title text-20 mb-2 font-weight-bold">
            {{ getCourseTitle(daily.day, `: ${daily.content[0].title}`) }}
          </div>
          <div class="course-card-wrapper d-flex align-items-center">
            <course-card
              v-for="(course, index) in daily.content"
              :key="course.courseUniqueKey"
              :is-lock="daily.isLock"
              :course="course"
              :course-number="index"
              :daily-number="daily.day"
            >
            </course-card>
          </div>
        </div>
      </div>
      <live-card
        v-for="liveCourse of dailyLiveList"
        :key="liveCourse.liveCourseId"
        :live-course="liveCourse"
        :show-tags="false"
        :show-subscription="false"
        class="daily-live-card"
        @enterLiveRoom="enterLiveRoom"
        @watchRecord="watchRecord"
        @copy="copy"
      ></live-card>
      <ShoppingGuideCard
        v-if="isShoppingGuideShow"
        :title="$t('覺得圍棋簡單又好玩嗎？')"
        :sub-title="$t('想學得更多！點此前往官網購買')"
        @onClickShoppingGuide="onClickShoppingGuide"
      ></ShoppingGuideCard>
    </template>
  </div>
</template>

<script>
import CourseCard from '@/components/Course/CourseCard.vue';
import LiveCard from '@/components/Live/LiveCard.vue';
import ShoppingGuideCard from '@/components/Course/ShoppingGuideCard.vue';
import aiCoursesService from '@/services/aiCourse.js';
import plansMixin from '@/mixin/plans';
import DefaultVideoImg from '@/assets/img/common/default-video-img.png';
import liveCourseService from '@/services/liveCourse';
import {Browser} from '@capacitor/browser';
import copyToClipboard from '@/lib/base/copyToClipboard.js';
import {manifest, stage} from '@/constant/env.js';

export default {
  components: {
    CourseCard,
    LiveCard,
    ShoppingGuideCard,
  },
  mixins: [plansMixin],
  props: {
    liveList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      videoCovers: {},
      isDataLoading: false,
    };
  },
  computed: {
    currentCourses() {
      return this.$store.getters['course/currentCourses'] || [];
    },
    dailyLiveList() {
      return this.$store.state.liveCourse.dailyLiveList;
    },
    isShoppingGuideShow() {
      return (
        (this.isPlanKidtrial_02 || this.isPlanTrial_01) &&
        this.currentCourses[this.currentCourses.length - 1]?.content[0]
          .status === 'COMPLETED'
      );
    },
  },
  watch: {
    currentCourses() {
      this.getVideoCovers();
    },
  },
  beforeDestroy() {
    this.$store.commit('course/setAnchorDay', null);
    // 離開此頁即取消 anchorDay 之定位點（從個人點擊號碼進入所設定的錨點）
  },
  created() {
    this.getVideoCovers();
  },
  methods: {
    async getVideoCovers() {
      try {
        this.isDataLoading = true;
        const promises = this.currentCourses
          .filter((course) => course.content[0].aiCourseId)
          .map((course) => {
            return aiCoursesService.getVideoPlayAuth(
              course.content[0].aiCourseId,
              course.content[0].videoId
            );
          });
        const results = await Promise.all(promises);
        this.videoCovers = results.reduce((acc, curr) => {
          if (curr?.VideoMeta?.VideoId) {
            acc[curr?.VideoMeta?.VideoId] = {
              CoverURL: curr?.VideoMeta?.CoverURL,
              videoId: curr?.VideoMeta?.VideoId,
            };
          }
          return acc;
        }, {});
        this.isDataLoading = false;
      } catch (error) {
        this.isDataLoading = false;
      }
    },
    getOpenDay(openedAt) {
      return this.$day(openedAt).format('MM/DD');
    },
    getVideoCover(videoId) {
      if (this?.videoCovers?.[videoId]?.CoverURL) {
        return this.videoCovers[videoId].CoverURL.replace(
          'http://',
          'https://'
        );
      } else {
        return DefaultVideoImg;
      }
    },
    getCourseTitle(day, title) {
      if (day === 0) {
        return this.$t('如何使用平台');
      }
      const newDayText = String(day).split('.').join('-');
      return this.isPlan30k_10k
        ? `Day ${newDayText} ${title}`
        : `${this.$t('第 {newDayText} 堂', {newDayText})} ${title}`;
    },
    hasLastWatch(daily) {
      return daily.content.some((course) => {
        return course.isLastWatch;
      });
    },
    onClickVideo(daily) {
      if (daily.isLock) {
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      this.$store.commit('aiCourse/setUrlData', {
        aiCourseId: daily.content[0].aiCourseId,
        dailyNumber: daily.day,
        courseUniqueKey: encodeURIComponent(daily.content[0].courseUniqueKey),
        videoId: daily.content[0].videoId,
      });
    },
    async enterLiveRoom(liveCourse) {
      Browser.open({
        url: liveCourse.streamingJoinUrl,
        presentationStyle: 'popover',
      });
      await this.sendRecord(liveCourse.liveCourseId);
    },
    async watchRecord(liveCourse) {
      try {
        this.$store.commit('env/setIsLoading', true);
        const {data: url} = await liveCourseService.getLiveCoursesRecord({
          liveCourseId: liveCourse.liveCourseId,
          classId: liveCourse.classId,
        });
        if (!url) {
          return this.$Message.error(this.$t('此直播無法回放'));
        }
        await Browser.open({
          url,
          presentationStyle: 'popover',
        });
        await this.sendRecord(liveCourse.liveCourseId);
      } catch (error) {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    async sendRecord(livecourseId) {
      await liveCourseService.postLiveCourseRecord(
        livecourseId,
        this.$day().valueOf()
      );
    },
    copy(meetingId) {
      copyToClipboard(meetingId);
      this.$Message.success(this.$t('複製成功'));
    },
    onClickShoppingGuide() {
      let url = `${manifest.homePageUrl}/courses`;

      if (stage === 'beta') {
        url =
          'http://ec2-13-230-41-83.ap-northeast-1.compute.amazonaws.com/courses';
      }
      return Browser.open({
        url,
        presentationStyle: 'popover',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.daily-courses {
  &.app-daily {
    .daily-course:not(:last-child) {
      margin-bottom: 8px;
    }
    .header {
      background: $secondary;
      border-radius: $rounded-md;
      height: 50px;
      line-height: 50px;
    }
    .daily-live-card {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  &.web-daily {
    .daily-course {
      height: 128px;
      margin-top: 16px;
      box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
      border-radius: $rounded-md;
    }
    .mask {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $grayscale-30;
      z-index: 1;
      border-radius: 10px;
    }
    .lock {
      top: 0;
      bottom: 0;
      right: 30px;
    }
    .cover-wrapper {
      background: $bgsection;
      min-width: 170px;
      border-radius: $rounded-md;
      cursor: pointer;
      &:hover {
        .cover-bg {
          display: flex;
        }
      }
    }
    .keep-learning {
      top: 0;
      right: 0;
      border-radius: 0 10px 0 10px;
      padding: 3px 18px;
      line-height: 28px;
    }
    .cover-bg {
      display: none;
      z-index: 3;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
    }
    .btn-wrapper {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
    .icon-Play {
      font-size: 28px;
    }
    .daily-live-card {
      margin-bottom: 0;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
  /deep/ .sub-title {
    color: $font-third;
    font-size: 14px;
    font-weight: normal !important;
    line-height: 22px;
    margin-top: 8px;
  }
}
</style>
