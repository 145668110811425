<template>
  <div>
    <b-modal
      ref="modal"
      class="modal-confirm"
      content-class="rounded-10 position-relative"
      size="xl"
      hide-footer
      hide-header
      title="提示"
      no-close-on-backdrop
      :visible="true"
      centered
      @hide="closeGuide"
      @close="closeGuide"
    >
      <div class="header text-18">
        {{ $t('提示') }}
        <span
          class="btn-close text-primary cursor-pointer position-absolute text-32 icon-X"
          @click="closeGuide"
        >
        </span>
      </div>
      <div class="main">
        <img class="img-fluid" :srcset="guideImg" alt="" />
        <p class="text-center text-fontGratscal1 mb-2 mt-3">
          {{ $t('點擊') }}

          <span class="text-primary font-weight-bold">
            “{{ $t('切換課程') }}”</span
          >
          {{ $t('按鈕可切換購買的新課程喔') }}
        </p>
        <b-button
          variant="primary"
          size="md"
          class="d-block mx-auto text-14"
          style="width: 150px; line-height: 18px"
          @click="closeGuide"
        >
          {{ $t('知道了') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import guideImg from '@/assets/img/guide-img.png';

export default {
  data() {
    return {
      guideImg,
    };
  },
  methods: {
    closeGuide() {
      this.$emit('closeGuide');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal-dialog {
  max-width: 700px;
  color: $font-grayscale-1;
  .modal-body {
    padding: 0;
  }
  .btn-close {
    top: 14px;
    right: 6px;
  }
  .header {
    padding: 15px 16px;
    border-bottom: 1px solid $line-1;
  }
  .main {
    padding: 16px;
  }
}
</style>
