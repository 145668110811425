<template>
  <div class="modal-get-coupon-wrapper">
    <modal-wrapper :is-modal-wrapper-show="true">
      <div
        class="modal-get-coupon position-relative d-flex flex-column justify-content-center align-items-center"
        @click.stop
      >
        <div class="position-relative">
          <b-img class="img-get-coupon mt-4" :srcset="couponImages"> </b-img>
          <span
            class="get-coupon-state text-white font-weight-bold position-absolute"
          >
            {{ couponRibbon }}
          </span>
        </div>
        <p
          class="get-coupon-content mt-3 mb-2 text-primary font-weight-bold text-center"
        >
          {{ couponTitle }}
        </p>
        <div class="wrap-btn">
          <p class="mb-2 mx-4 text-center text-14 text-font-grayscale-1">
            {{ couponMessage }}
          </p>
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="secondary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="mr-2"
              @click="onItemClick('close')"
            >
              {{ $t('關閉') }}
            </b-button>
            <b-button
              variant="primary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="ml-2"
              @click="onItemClick('check-coupon')"
            >
              {{ $t('前往查看') }}
            </b-button>
          </div>
        </div>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
export default {
  components: {
    ModalWrapper,
  },
  props: {
    couponMessage: {
      type: String,
      default: '',
    },
    couponTitle: {
      type: String,
      default: '',
    },
    couponRibbon: {
      type: String,
      default: '開始新旅程',
    },
  },
  data() {
    return {
      modalShow: false,
    };
  },
  computed: {
    couponImages() {
      return require('@/assets/img/modal/get-coupon.png?srcset');
    },
  },
  methods: {
    onItemClick(value) {
      this.$emit('item-click', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-get-coupon {
  background: $bgsection;
  border-radius: 30px;
  width: 300px;
  min-height: 374px;
  @media screen and (min-width: 768px) {
    width: 390px;
  }
  .get-coupon-content {
    font-size: 20px;
    line-height: 34px;
    @media screen and (min-width: 768px) {
      font-size: 28px;
      line-height: 44px;
    }
  }
  .wrap-btn {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background: white;
    padding: 16px 0;
    .btn-infoAndLink:disabled,
    .btn-infoAndLink.disabled {
      background: #ced4da;
      border-color: #ced4da;
    }
    .notice-reviewed {
      font-size: 12px;
      color: $font-grayscale-1;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .img-get-coupon {
    width: 230px;
    height: 230px;
    @media screen and (min-width: 768px) {
      width: 320px;
      height: 320px;
    }
  }
  .get-coupon-state {
    top: 79%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 28px;
    @media screen and (min-width: 768px) {
      font-size: 38px;
    }
  }
  .text-font-grayscale-1 {
    color: #606266;
  }
}
</style>
