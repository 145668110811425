<template>
  <img
    class="home-banner h-100 w-100"
    :class="$isJungo ? 'rounded-md' : 'cursor-pointer'"
    :srcset="getImg"
    @click="onClickBanner"
  />
</template>

<script>
import _ from 'lodash';
import {Browser} from '@capacitor/browser';
import {manifest} from '@/constant/env.js';

export default {
  data() {
    return {};
  },
  computed: {
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    isKidType() {
      return this.currentPlan?.id?.substring(0, 3) === 'kid';
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    randomPic() {
      return _.random(1, 3);
    },
    getImg() {
      const imgHead = this.$device.isMobile ? 'mobile' : 'web';
      if (this.$isJungo) {
        return require(`@/assets/img/home/${imgHead}-banner.png?srcset`);
      } else if (this.currentPlan.isNFT) {
        return require(`@/assets/img/home/${imgHead}-banner-nft-1.png?srcset`);
      } else
        return require(`@/assets/img/home/${imgHead}-banner-${
          this.isKidType ? 'kid-' : ''
        }${this.randomPic}.png?srcset`);
    },
    hasUnfinishedGame() {
      return this.$store.getters['aiGame/hasUnfinishedGame'];
    },
  },
  methods: {
    async onClickBanner() {
      if (this.$isJungo) {
        return;
      }
      if (this.currentPlan.isNFT) {
        const homePageUrl = manifest.homePageUrl;

        return Browser.open({
          url: homePageUrl + '/events/gothroughtheages/',
          presentationStyle: 'popover',
        });
      }
      switch (this.randomPic) {
        case 1:
          if (this.$device.isMobile) {
            this.$router.push({
              name: 'tsumelist',
            });
          } else {
            this.$router.push({
              name: 'practiceTsume',
              params: {rank: 'uncast', type: 'uncast'},
            });
          }
          break;
        case 2:
          this.$store.commit('env/setIsLoading', true);
          await this.getUnfinishedGame();
          if (this.hasUnfinishedGame && this.$device.isMobile) {
            this.$router.push('/practice/game');
          } else {
            this.$device.isMobile
              ? this.$router.push('/practice/gamelist')
              : this.$router.push({name: 'practiceGame'});
          }
          break;
        case 3:
          this.$router.push({
            name: 'liveList',
          });
          break;
      }
    },
    async getUnfinishedGame() {
      await this.$store.dispatch('aiGame/getUnfinishedGame');
      this.$store.commit('env/setIsLoading', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-banner {
  object-fit: cover;
}
</style>
