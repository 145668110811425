<template>
  <div
    class="
      no-lesson-text
      text-center
      d-flex
      align-items-center
      justify-content-center
      w-100
      rounded-10
    "
  >
    <div class="wrapper">
      <h2 class="mb-2">{{ $t('黑嘉嘉圍棋教室') }}</h2>
      <p class="mb-4" style="line-height: 28px">
        {{ $t('您還沒有購買任何課程，快來加入我們一起快樂學圍棋！') }}
      </p>
      <b-button variant="primary" size="md" @click="onClick2Web">
        {{ $t('前往官網了解') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {manifest} from '@/constant/env.js';

export default {
  methods: {
    onClick2Web() {
      window.open(manifest.homePageUrl);
    },
  },
};
</script>
<style lang="scss">
.no-lesson-text {
  height: 100%;
  .wrapper {
    color: black;
    width: 272px;
  }
  h2 {
    letter-spacing: 4px;
  }
}

@media screen and (min-width: 768px) {
  .no-lesson-text {
    padding: 114.5px 0;
    background: white;
  }
}
</style>
