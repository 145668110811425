var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course position-relative bg-white d-flex align-items-center justify-content-between overflow-hidden",class:[
      _vm.$device.isMobile ? 'app-course px-4' : 'web-course pr-2',
      {'shadow-md': _vm.$device.isMobile && _vm.$isJungo},
      {'shadow-sm': _vm.$device.isMobile && !_vm.$isJungo},
      {
        'is-last-watch': _vm.course.isLastWatch,
        'is-chosen': _vm.anchorDay === _vm.dailyNumber && _vm.courseNumber === 0,
      },
    ],on:{"click":_vm.onClickCourse}},[(_vm.$device.isMobile)?[(_vm.isLock)?_c('div',{staticClass:"mask position-absolute"},[_c('i',{staticClass:"lock icon-Lock d-flex align-items-center text-32 text-white position-absolute"})]):_vm._e(),_c('div',{staticClass:"course-details d-flex align-items-center"},[(!_vm.$device.isMobile)?_c('div',{staticClass:"order text-white d-flex align-items-center justify-content-center mr-4 bg-primary"},[_vm._v(" "+_vm._s(_vm.courseNumber + 1)+" ")]):_vm._e(),_c('div',{staticClass:"content w-100 text-truncate"},[_c('h3',{staticClass:"content-title text-16 font-weight-normal w-100 text-truncate"},[_vm._v(" "+_vm._s(_vm.courseTitle)+" ")]),_c('p',{staticClass:"content-subtitle text-14 m-0 text-truncate"},[_vm._v(" "+_vm._s(_vm.vedioTimeText)+" ")])])]),(_vm.isPassed)?_c('div',{staticClass:"check d-flex"},[_c('i',{staticClass:"icon-Circle-check"})]):_vm._e()]:[_c('b-button',{staticClass:"course-btn",attrs:{"variant":(!_vm.$isJungo && _vm.course.type === 'VIDEO') ||
          (_vm.$isJungo && _vm.course.type !== 'VIDEO')
            ? 'primary'
            : 'secondary',"size":"md"}},[_c('p',{staticClass:"m-0 text-truncate d-flex align-items-center"},[(_vm.course.type === 'VIDEO')?_c('i',{staticClass:"icon-Circle-play text-20 mr-1"}):_vm._e(),_vm._v(" "+_vm._s(_vm.webCourseTitle)+" "),(_vm.isPassed)?_c('span',{staticClass:"icon-Circle-check ml-1 text-20"}):_vm._e()])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }