<template>
  <div
    class="bg-primary text-white d-flex align-items-center justify-content-between rounded-md p-4 text-18 cursor-pointer mt-2 mt-md-3 font-weight-bold"
    @click="$emit('onClickShoppingGuide')"
  >
    <div class="left">
      <p class="m-0">{{ title }}</p>
      <p class="m-0">{{ subTitle }}</p>
    </div>
    <div class="right">
      <i class="icon-Chevron-right d-block text-32"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 30px;
}
</style>
