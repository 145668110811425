<template>
  <b-navbar class="home-header sticky-top justify-content-between w-100 p-0">
    <b-navbar-brand class="cursor-pointer p-0 h-100">
      <img
        class="logo-orange h-100"
        :srcset="require('@/assets/img/logo-orange-transparent.png?srcset')"
      />
    </b-navbar-brand>
    <div class="menu-wrapper position-relative">
      <div
        v-if="hasVerificationNotification"
        class="menu-dot bg-accent position-absolute"
      ></div>
      <i
        class="icon-Menu text-32 text-white d-block"
        @click="onClickSidebar"
      ></i>
    </div>
    <b-navbar-nav>
      <b-nav-item v-if="hasMultiplePlans" href="#" @click="onClickSwitchCourse">
        <i class="icon-Arrow-left-right text-32 d-block"></i>
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  computed: {
    currentCourses() {
      return this.$store.getters['course/currentCourses'];
    },
    plans() {
      return this.$store.getters['course/plans'] || [];
    },
    hasMultiplePlans() {
      return this.plans.length > 1;
    },
    hasVerificationNotification() {
      return this.$store.state.env.hasVerificationNotification;
    },
  },
  methods: {
    onClickSwitchCourse() {
      this.$emit('switch-course');
    },
    onClickSidebar() {
      this.$emit('on-click-sidebar');
    },
  },
};
</script>

<style lang="scss" scoped>
.home-header {
  transition: all 0.3s;
  height: 56px;
  min-height: 56px;
  top: -1px;
  animation: delayShow 0.2s forwards;
  i {
    transition: all 0.3s;
  }
  .nav-item {
    margin-right: 8px;
    a {
      padding: 6px;
    }
    &:last-child {
      margin-right: 6px;
    }
  }

  .menu-wrapper {
    display: none;
  }
  .icon-Menu {
    padding: 6px;
    margin-left: 6px;
  }
  .menu-dot {
    width: 14px;
    height: 14px;
    top: 6px;
    right: 6px;
    border-radius: 50%;
    z-index: 5;
  }

  .notification-dot {
    width: 14px;
    height: 14px;
    top: 5px;
    right: 8px;
    border-radius: 50%;
  }
  .icon-Arrow-left-right,
  .icon-Bell {
    color: $primary;
  }
  &.second-mode {
    background: $secondary;
    color: $white;
    .navbar-brand {
      display: none;
    }
    .menu-wrapper {
      display: block;
    }
    .icon-Arrow-left-right,
    .icon-Bell {
      color: $white;
    }
  }
}
@keyframes delayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
